import React from "react";
import Canvas from "./components/Canvas";
import Screen from "./components/Screen";
import { useSelector } from "react-redux";
import ScreenBar from "./components/ScreenBar";
export default function CanvasScreen({ children, language }) {
 
  const { Height } = useSelector((state) => state.userAppReducer);

let height=Height+400
  return (
    <div
    style={{ height: `${height}px`,}}
      id="question"
      // className={`z-20 w-full h-screen p-0 bg-gray-100 flex flex-col font-[Almarai] md:max-w-[875px] md:m-auto `}
      // className={`bg-red-500  h-[1217px]`}
      className={`  `}
    >
       <Screen>
        <Canvas   language={language}>
          {children}
        </Canvas>
      </Screen>
    </div>
  );
}
