/* eslint-disable jsx-a11y/iframe-has-title */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CanvasScreen from "../canvasScreen/CanvasScreen";
import {
  AccessibleScreen,
  Controllers,
  EssayModal,
  Pagination,
  Timer,
} from "../components";
import Loading from "../components/examCompo/Loading";
import NoQuestions from "../components/examCompo/NoQuestions";
import TheRenderQuesBody from "../components/questions/AglopalQuestionMethod/RenderQuesBody";
import ReviewRow from "../components/ReviewCompo/ReviewRow";
import Toast from "../components/Toast";
import config from "../sql/config.json";
import {
  setBlur,
  setBriefReport,
  setCurrentQues,
  setExamConfig,
  setExamCounter,
  setFullReport,
  setModal,
  setModalBody,
  setModalBotton,
  setModalSubTitle,
  setModalTitle,
  setQuestionsList,
  setStartExam,
  setTimer,
  setUserAnswer,
} from "../store/actions";
import ScreenBar from "../canvasScreen/components/ScreenBar";

export default function Review() {
  const params = useParams();
  const navigate = useNavigate();
  // const exams = require("../data/exams/" + params.id + ".json");
  // const isAuthenticated = useIsAuthenticated();

  const dispatch = useDispatch();
  const [exams, setExams] = useState([]);
  const {
    lang,
    currentQues,
    blur,
    modal,
    examCounter,
    briefReport,
    fullReport,
    userAnswer,
    essayModal,
  } = useSelector((state) => state.userAppReducer);
  const [questions, setQuestions] = useState(exams);
  const [userIfAnswer, setUserIfAnswer] = useState(false);
  const [modelEssayEvaluation, setModelEssayEvaluation] = useState(false);

  const [accessable, setAccessable] = useState(true);

  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState();

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
    //     console.log(status);

    return false;
  };

  const mytoken = localStorage.getItem("token");
  const headers = {
    "access-token": "qwertasdfg123@+1",
    Authorization: `Bearer ${mytoken}`,
    // Authorization  : `Bearer ${mytoken}`
  };
  useEffect(() => {
    getExam();
  }, [selectedStatus]);

  const [filters, setFilters] = useState({});

  const handleFiltersChange = (newFilters) => {
    setFilters(newFilters);
    getExam(newFilters);
  };
  const getExam = async (filters) => {
    // /data/exams/" + params.id + ".json
    setLoading(true);

    await axios
      .get(`https://emmobank.com/api/get_all`, {
        params: filters,
        headers: headers,
      })
      .then(async (responses) => {
        //
        setExams(responses.data.issues);
        setQuestions(responses.data.issues);
        //
        dispatch(setQuestionsList(responses.data.issues));

        return responses.data.issues;
      })
      .then((e) => {
        //  console.log(e);

        setTimeout(() => {
          setLoading(false);
          dispatch(setBlur(true));
          dispatch(setBriefReport(false));
          dispatch(setFullReport(false));
          dispatch(setCurrentQues(0));
          dispatch(setStartExam(false));
          dispatch(setModal(false));
          // dispatch(setQuestionsList(e));
          dispatch(
            setUserAnswer(
              localStorage.getItem("userAnswer_" + params.id) !== null
                ? JSON.parse(localStorage.getItem("userAnswer_" + params.id))
                : []
            )
          );
          dispatch(
            setTimer(
              localStorage.getItem("userTime_" + params.id) !== null
                ? localStorage.getItem("userTime_" + params.id) - e.duration
                : e.duration
            )
          );
          // @ts-ignore
          window.bookmark = [];
          // @ts-ignore
          window.report = [];
          // @ts-ignore
          window.time = e.duration;
          let a = localStorage.getItem("userTime_" + params.id);
          let b = e.duration;
          if (a > b) {
            localStorage.setItem("userTime_" + params.id, 0);
          }
          // @ts-ignore
          // window.userTime = (localStorage.getItem("userAnswer_" + params.id)) ? parseInt(localStorage.getItem("userTime_" + params.id)) : 0;
          setUserIfAnswer(
            localStorage.getItem("userAnswer_" + params.id)
              ? localStorage.getItem("userAnswer_" + params.id).length
              : 0
          );
          startTimerMain();
          // @ts-ignore
          //
          //
          dispatch(setExamCounter(e.length));
          if (
            userAnswer.length <= 0 ||
            briefReport === true ||
            fullReport === true
          ) {
            // @ts-ignore
            dispatch(setModal(false));
            dispatch(setStartExam(true));
            dispatch(setBriefReport(false));
            dispatch(setFullReport(false));
          } else {
            // @ts-ignore
            dispatch(setStartExam(false));
            // @ts-ignore
            dispatch(setModalBotton([...buttonsListForModelFinish]));
            // @ts-ignore
            dispatch(setModalTitle(""));
            // @ts-ignore
            dispatch(
              setModalSubTitle(
                config.config[exams?.meta_data?.language === "ar" ? "ar" : "en"]
                  .takenBody
              )
            );
            // @ts-ignore
            dispatch(setModalBody(""));
            // @ts-ignore
            dispatch(setBlur(true));
            // @ts-ignore
            dispatch(setModal(true));
          }
          // @ts-ignore
          dispatch(setExamConfig(questions));
          setTimeout(() => {
            // @ts-ignore
            setIsLoading(document.readyState === "complete" ? 0 : 1);
          }, 100);
          if (params.index) {
            dispatch(setModal(false));
            dispatch(setStartExam(false));
            dispatch(setBriefReport(false));
            dispatch(setBlur(false));
            dispatch(setCurrentQues(parseInt(params.index) - 1));
          }
        }, 10);
        setLoading(false);
      })
      .catch(() => {})
      .finally(() => {});
  };

  const startTimeFrom = () => {
    window.timer = true;
  };

  const closeModel = () => {
    // @ts-ignore
    dispatch(setBlur(false));
    // @ts-ignore
    dispatch(setModal(false));
    if (exams.timer === true) {
      startTimeFrom();
    }
  };

  const buttonsListForModelFinish = [
    {
      text: config.config[exams?.meta_data?.language === "ar" ? "ar" : "en"]
        .complete,
      color: "text-white", // tail wind css color
      border: "border", //tail wind css border
      borderColor: "border-primary", //tail wind css border
      background: "bg-primary",
      action: closeModel,
    },
    {
      text: config.config[exams?.meta_data?.language === "ar" ? "ar" : "en"]
        .Retake,
      color: "text-[#151048]", // tail wind css color
      border: "border", //tail wind css border
      borderColor: "border-gray-300", //tail wind css border
      background: "bg-gray-300",
      action: () => {
        localStorage.removeItem("userTime_" + params.id);
        localStorage.removeItem("userAnswer_" + params.id);
        window.location.reload(false);
      },
    },
  ];

  const toggleModel = () => {
    // @ts-ignore
    dispatch(setBlur(!blur));
    // @ts-ignore
    dispatch(setModal(!modal));
  };

  const CloseModel = () => {
    // @ts-ignore
    dispatch(setBlur(false));
    // @ts-ignore
    dispatch(setModal(false));
  };

  const ViewBriefReport = () => {
    dispatch(setBlur(false));
    // @ts-ignore
    dispatch(setModal(false));
    // @ts-ignore
    setModelEssayEvaluation(false);
    dispatch(setBriefReport(true));

    // @ts-ignore
    window.timer = false;
  };

  const finishExam = () => {
    // @ts-ignore
    dispatch(
      setModalBotton([
        {
          text: config.config["ar" || "ar"].yes,
          color: "text-white", // tail wind css color
          border: "border", //tail wind css border
          borderColor: "border-primary", //tail wind css border
          background: "bg-primary",
          action: ViewBriefReport,
        },
        {
          text: config.config["ar" || "ar"].no,
          color: "text-[#151048]", // tail wind css color
          border: "border", //tail wind css border
          borderColor: "border-gray-500", //tail wind css border
          background: "bg-gray-200",
          action: CloseModel,
        },
      ])
    );

    // @ts-ignore
    dispatch(setModalTitle(questions.title));
    // @ts-ignore
    dispatch(setModalSubTitle(config.config["ar" || "ar"].ConfirmFinishExam));
    dispatch(setModalBody(""));
    toggleModel();
  };

  function startTimerMain() {
    const duration = 0;
    // const duration = exams.duration;
    const pad = (n) => (n < 10 ? `0${n}` : n);
    const h = Math.floor(duration / 3600);
    const m = Math.floor(duration / 60) - h * 60;
    const s = Math.floor(duration - h * 3600 - m * 60);
    // @ts-ignore
    window.time = h + ":" + pad(m) + ":" + pad(s);

    // @ts-ignore
    dispatch(setTimer(h + ":" + pad(m) + ":" + pad(s)));
  }
  const gotoDivision = () => {
    navigate("/home");
  };

  if (loading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }
  if (questions?.length === 0) {
    return (
      <div>
        <NoQuestions text={"NO Question to Review"}>
          <ReviewRow
            quData={questions?.[currentQues]}
            onStatusChange={handleStatusChange}
            onFiltersChange={handleFiltersChange}
          />
        </NoQuestions>
      </div>
    );
  }

  if (questions) {
    return (
      <>
        <ScreenBar />

        <ReviewRow
          quData={questions?.[currentQues]}
          onStatusChange={handleStatusChange}
          onFiltersChange={handleFiltersChange}
        />
        <img
          // className="w-full"
          className={`    border-dashed border-red-600 border-4 justify-center  p-0 bg-gray-100 flex flex-col     w-[900px] m-auto `}
          src={questions?.[currentQues].imgae}
          alt="oldImage"
        />
        <CanvasScreen
          language={questions?.[currentQues]?.question?.language_code || "ar"}
        >
          <Toast message="تمت العملية بنجاح" type="success" />
          <div
            dir={
              questions?.[currentQues]?.question?.language_code === "ar"
                ? "rtl"
                : "ltr"
            }
            className={`w-full absolute z-10 h-fit p-0 bg-gray-100 flex flex-col font-[Almarai] md:m-auto `}
          >
            <div id="loadImagesToLocal" className="hidden"></div>

            {accessable === true ? (
              true ? (
                <>
                  {fullReport === false ? (
                    <Timer active={exams.timer} />
                  ) : (
                    <></>
                  )}

                  <div
                    dir={
                      questions?.[currentQues]?.question?.language_code === "ar"
                        ? "rtl"
                        : "ltr"
                    }
                    className={`bg-white w-full h-full
                 overflow-auto`}
                  >
                    <div
                      className={`min-h-full flex-col flex justify-between px-2 sm:px-10 pb-5`}
                    >
                      <div className=" relative p-1  bg-white    rounded-b-xl  shadow-lg  ">
                        <TheRenderQuesBody
                          question={questions?.[currentQues]?.question}
                        />
                      </div>

                      <div className="flex flex-col h-fit justify-center">
                        <Controllers
                          lang={
                            questions?.[currentQues]?.question?.language_code ||
                            "ar"
                          }
                        />
                        {/* <Controllers lang={questions?.[currentQues]?.question?.language_code || "ar"} /> */}
                        <Pagination type={exams} />
                        {fullReport !== true ? (
                          <button
                            onClick={() => finishExam()}
                            className="w-full sm:w-52 m-auto border-primary text-white bg-primary border text-center py-2 px-4 rounded-full  font-['roman'] rtl:font-['Droid']"
                          >
                            {config.config["ar" || "ar"].finishExam}
                          </button>
                        ) : (
                          <button
                            onClick={() => dispatch(setBriefReport(true))}
                            className="w-full sm:w-52 m-auto border-primary text-white bg-primary border text-center py-2 px-4 rounded-full  font-['roman'] rtl:font-['Droid']"
                          >
                            {config.config["ar" || "ar"].briefRprtBtn}
                          </button>
                        )}
                      </div>

                      <EssayModal
                        active={essayModal}
                        lang={
                          questions?.[currentQues]?.question?.language_code ||
                          "ar"
                        }
                        exam={exams}
                      />
                      <div className="h-10" />
                    </div>
                  </div>
                </>
              ) : (
                ""
              )
            ) : (
              <AccessibleScreen
                active={true}
                lang={lang}
                action={() => gotoDivision()}
              />
            )}
          </div>
        </CanvasScreen>
      </>
    );
  } else {
    return (
      <>
        <Loading />
      </>
    );
  }
}
