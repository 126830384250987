 
//متجيش نحيته
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { isDesktop, isTablet } from "react-device-detect";
import {  useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export const ResizeImage = (props) => {
  const params = useParams();
   const [isLandscape, setIsLandscape] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );
  const { currentQues, userAnswer, examConfig } =
    useSelector((state) => state.userAppReducer);
   useEffect(() => {
    window.imagesCount = 0;
    resizeImage();
  }, [isLandscape]);

  useEffect(() => {
    function handleOrientationChange(mql) {
      setIsLandscape(mql.matches);
    }
    const mediaQueryList = window.matchMedia("(orientation: portrait)");
    mediaQueryList.addListener(handleOrientationChange);

    // Clean up the listener function when the component unmounts
    return () => {
      mediaQueryList.removeListener(handleOrientationChange);
    };
  }, []);

  const resizeImage = (text, type, size) => {
    // return text;
    $("#txt").html(text);
    let MainHeight = 30;
    let MainWidth = 30;
    let _Height = 12.43;
    let _Width = 98.34;
    var images = $("#txt").find("img");
    if (isDesktop) {

    } else if (isTablet) {
      
    } else {
     
    }

 

    let cont = $("#txt").find("div");
    for (let index = 0; index < cont.length; index++) {
      if ($(cont[index]).css("text-align") == "left") {
        $(cont[index]).attr("dir", "ltr");
      }
    }
    let cont_2 = $("#txt").find("p");
    for (let index = 0; index < cont_2.length; index++) {
      if ($(cont_2[index]).css("text-align") == "left") {
        $(cont_2[index]).attr("dir", "ltr");
      }
    }
 
    if (!window.blanks) {
      window.blanks = function (myAnswer, rightAnswer, index, i, count) {
        const localAnswersStringify = localStorage.getItem(
          "userAnswer_" + params.id
        );
        const localAnswers =
          localAnswersStringify && localAnswersStringify !== null
            ? JSON.parse(localAnswersStringify)
            : [];
        let a = {
          answer: myAnswer,
          index: index,
          parent: false,
          right: rightAnswer,
          type: "blanks",
          score: props?.question?.QuesScore,
          userScore:
            parseInt(myAnswer) == parseInt(rightAnswer)
              ? parseInt(props.question.QuesScore)
              : 0,
          totalAns: count,
        };
        if (!localAnswers?.[index]?.[0]) {
          localAnswers[index] = [];
          localAnswers[index][i] = a;
        } else {
          localAnswers[index][i] = a;
        }
        // dispatch(userAnswer)
        localStorage.setItem(
          "userAnswer_" + params.id,
          JSON.stringify(localAnswers)
        );
        return true;
      };
    }
    let index = 0;

    let htmlCode = $("#txt").html();
    if (htmlCode?.includes("##")) {
      //  
      const matches = htmlCode.match(/##(.*?)##/g);
      const count = matches ? matches.length : 0;
      let a = htmlCode.replace(/##(.*?)##/g, (match, rightAnswer) => {
        let ind = index++;
        //  
        // let val=JSON.parse(localStorage.getItem(`userAnswer_${params.id}`))?.[0]?.answer||"";
        //  
        const userAnsLength = Math.min(
          userAnswer?.[currentQues]?.[ind]?.answer
            ? userAnswer?.[currentQues]?.[ind]?.answer?.length * 10 + 50
            : rightAnswer?.length * 10 + 50,
          400
        );
        const ansLength = Math.min(rightAnswer?.length * 10 + 50, 400);
         
        const rightIconBox = `<svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        style="position: absolute;top:-3px; right: -3px; "
        className="
                    w-5 h-5 border -top-2 -right-2 z-10 absolute bg-white rounded-full
                    stroke-green-600   "
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M4.5 12.75l6 6 9-13.5"
        />
      </svg>`;
        const wrongIconBox = `<svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        style="position: absolute;top:-3px; right: -3px; "
        className="
                    w-5 h-5 border -top-2 -right-2 z-10 absolute bg-white rounded-full
                    stroke-red-600   "
      >
      <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6 18L18 6M6 6l12 12"
    />
      </svg>`;
        const rightInput = `<span style="width:fit-content; display:inline-block; position: relative"><input disabled value=${rightAnswer} type="text" style="width:${ansLength}px; text-align: center" className="border border-green-600 bg-green-100 h-9 max-w-full mx-1 inline-block  border-dashed rounded-lg py-1 px-1 font-extrabold"/>${rightIconBox}</span>`;

        return `<span style="width:fit-content; display:inline-block; position: relative">
        
        <input disabled value="${
          rightAnswer
        }" style="text-align: center; border-radius:14px" type="text" class="border border-green-600 bg-green-100 font-extrabold
         h-9 max-w-full mx-1 inline-block  border-dashed rounded-lg  px-1 " />
       </span>`
      });
       
      $("#txt").html(a);
    }

    let content = $("#txt").html();
    return content;
  };
  return (
    <div
      className="w-full"
      dir={examConfig?.meta_data?.language == "ar" ? "rtl" : "ltr"}
    >
      <div className="hidden border border-green-600 bg-green-100 font-extrabold h-9 max-w-full mx-1  border-dashed rounded-lg py-1 px-1" />
      <div
        id="txt"
        className={`hidden ${
          examConfig?.meta_data?.grade <= 8 ? "font-teshrin" : "font-times"
        }`}
      ></div>
      <div
        id={`${
          examConfig?.meta_data?.grade <= 8
            ? "quest-font-teshrin"
            : "quest-font-times"
        }`}
        
        // Khaled commented on this request via Ahmed
        // onDragExit={props.lang == "ar" ? "ltr" : "rtl"}
        className={` ${
          props.fonts
            ? props.fonts
            : examConfig?.meta_data?.grade <= 8
            ? "font-teshrin"
            : "font-times"
        } text-xl leading-[40px] sm:leading-[50px]   w-full`}
        dangerouslySetInnerHTML={{
          // __html: resizeImage(props.text, props.type, props.size),
          __html: `<style>${props.style}</style>${resizeImage(
            props.text,
            props.type,
            props.size
          )}`,
        }}
      ></div>
    </div>
  );
};
