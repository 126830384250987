import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalScreen from "./ModalScreen";
 import { showScreenModal, setScreen } from "../../store/actions";
import html2canvas from "html2canvas";

const Screen = ({ children, callback }) => {
  const ref = useRef(null);
  const [MyImg, setMyImg] = useState("");
  const [quid, setQuid] = useState("");

  const { screenValue,screenModalValue } = useSelector((state) => state.userAppReducer);
   const dispatch = useDispatch();

  const captureScreen = async () => {
    // console.log(ref.current);
    
    try {
      if (ref.current) {
        html2canvas(document.getElementById("question"),{ useCORS: true }).then((canvas) => {
          const imageUrl = canvas.toDataURL('image/png');
          setMyImg(imageUrl);
        });
      }
    } catch (error) {
      console.error("Error capturing or sending screenshot:", error);
    } finally {
      dispatch(setScreen(false));
      dispatch(showScreenModal(true));
    }
  };

  useEffect(() => {
 
    if (screenValue) {
      captureScreen();
    }
  }, [screenValue]);

  const { questionsList, currentQues } = useSelector(
    (state) => state.userAppReducer
  );

  useEffect(() => {
    if (questionsList?.id) {
      setQuid(questionsList?.id||0);
    } else if (questionsList[currentQues]?.question === undefined) {
      setQuid(questionsList[currentQues]?.id||0);
    } else {
      setQuid(questionsList[currentQues]?.question.id||0);
    }
  }, [screenModalValue]);

  return (
    <div
    ref={ref}
    
      className={`w-full justify-center  p-0 bg-gray-100 flex flex-col font-[Almarai] max-w-[875px] m-auto `}
    >


 
        {children}
        <ModalScreen MyImg={MyImg} quid={quid} />
    </div>
  );
};

export default Screen;
