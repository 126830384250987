import React, { useEffect, useRef, useState } from "react";
import CanvasDraw from "react-canvas-draw";
import {  useSelector } from "react-redux";
function Canvas({ children, language }) {
  const [Brushcolor, setBrush] = useState("red");
  const [brush, setThick] = useState(1);

  
  const { canvasClear,canvasUndo ,setShowToolValue} = useSelector((state) => state.userAppReducer);
 
  const canvasRef = useRef(null);

  useEffect(() => {
    if (canvasRef) {
      handleClear();
    }
    // console.log(canvasClear);
  }, [canvasClear]);

  useEffect(() => {
    if (canvasRef) {
      handleUndo();
    }
   }, [canvasUndo]);

  const handleClear = () => {
    canvasRef.current?.clear();
  };

  const handleUndo = () => {
    canvasRef.current?.undo();
  };

   
 
  const [width, setWidth] = useState(window.innerWidth);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window?.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);


 

  return (
    <div
      style={{
        backgroundColor: "transparent",
      }}
      className={`w-full h-screen p-0 bg-gray-100 flex flex-col font-[Almarai] md:max-w-[875px] md:m-auto `}
    >
      {/* <BackNavBar
        canBack={true}
        to="test"
        btns={
          <div className="flex flex-row  gap-2 w-full">
            {setShowToolValue ? (
              <>
                <button
                  className="text-white border   rounded"
                  onClick={handleClear}
                >
                  <TrashCanIcon />
                </button>
                <button
                  className="text-white border   rounded"
                  onClick={handleUndo}
                >
                  <BackIcon />
                </button>

                <button
                  className="text-white border   rounded"
                  onClick={tackScreenShot}
                >
                  <CameraIcon />
                </button>
              </>
            ) : null}

            <button
              className="text-white border   rounded"
              onClick={ShowBnsBar}
            >
              <BrushIcon />
            </button>
          </div>
        }
        language={language}
      /> */}

      <div
        style={{
          position: "relative",
          backgroundColor: "transparent",
        }}
      >
        {setShowToolValue && (
          <CanvasDraw
            style={{
              zIndex: 50,
              backgroundColor: "transparent",
              position: "absolute",
              top: 0,
              left: 0,
            }}
            ref={canvasRef}
            hideGrid={true}
            canvasWidth={width > 860 ? 860 : width}
            canvasHeight={
              document.getElementById("question")?.getBoundingClientRect()
                .height + 500
            }
            brushRadius={brush}
            brushColor={Brushcolor}
            catenaryColor="green"
            lazyRadius={0}
          />
        )}

        {children}
      </div>
    </div>
  );
}

export default Canvas;
