import React, { useEffect, useState } from "react";
import { BackNavBar } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import TrashCanIcon from "../svg/TrashCanIcon";
import BackIcon from "../svg/BackIcon";
import CameraIcon from "../svg/CameraIcon";
import BrushIcon from "../svg/BrushIcon";
import {
  setCanvasClear,
  setCanvasUndo,
  setScreen,
  setShowTool,
} from "../../store/actions";

export default function ScreenBar() {
  const [show, setShow] = useState();

  const { setShowToolValue } = useSelector((state) => state.userAppReducer);
  const dispatch = useDispatch();

  const handleShortcut = (event) => {
    if (event.shiftKey && event.key === "S") {
      event.preventDefault();
      tackScreenShot();
    }
  };
  const handleDrowShortcut = (event) => {
    if (event.shiftKey && event.key === "D") {
      event.preventDefault();
      ShowBnsBar();
    }
  };
  const ShowBnsBar = () => {
    setShow(!show);
    dispatch(setShowTool(!setShowToolValue));
  };
  useEffect(() => {
    window.addEventListener("keydown", handleShortcut);
    window.addEventListener("keydown", handleDrowShortcut);

    return () => {
      window.removeEventListener("keydown", handleShortcut);
      window.removeEventListener("keydown", handleDrowShortcut);
    };
  }, [setShowToolValue]);

  const tackScreenShot = () => {
    dispatch(setScreen(true));
  };

  const handleClear = () => {
    dispatch(setCanvasClear());
  };

  const handleUndo = () => {
    dispatch(setCanvasUndo());
  };

  return (
    <div>
      {" "}
      <BackNavBar
        canBack={true}
        to="test"
        btns={
          <div className="flex flex-row  gap-2 w-full">
            {setShowToolValue ? (
              <>
                <button
                  className="text-white border   rounded"
                  onClick={handleClear}
                >
                  <TrashCanIcon />
                </button>
                <button
                  className="text-white border   rounded"
                  onClick={handleUndo}
                >
                  <BackIcon />
                </button>

                <button
                  className="text-white border   rounded"
                  onClick={tackScreenShot}
                >
                  <CameraIcon />
                </button>
              </>
            ) : null}

            <button
              className="text-white border   rounded"
              onClick={ShowBnsBar}
            >
              <BrushIcon />
            </button>
          </div>
        }
        language={"en"}
      />
    </div>
  );
}
