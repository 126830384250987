import React, { useState, useEffect } from "react";
import {
  isMobileOnly,
  isTablet,
  isDesktop,
  withOrientationChange,
} from "react-device-detect";
import $ from "jquery";
import { useParams } from "react-router-dom";

export const HandelImagesWithText = (props) => {
  let { lesson, user, edit } = useParams();

  const [isLandscape, setIsLandscape] = useState(false);
  const [contentTxt, setContentTxt] = useState(null);
  const checkOrintation = () => {
    window
      .matchMedia("(orientation: portrait)")
      .addEventListener("change", (event) => {
        setIsLandscape(!event.matches);
      });
  };

  useEffect(() => {
    checkOrintation()
    resizeImage(props.text, props.type, props.size)
  }, [isLandscape, props]);

  const resizeImage = (text, type, size, ques) => {
    $("#txt").html(text);
  //   let MainHeight = 30;
  //   let MainWidth = 30;
  //   let _Height = 12.43;
  //   let _Width = 98.34;
  //   var images = $("#txt").find("img");
  //   if (isDesktop) {
  //     MainHeight = 40;
  //     MainWidth = 340.19;
  //     for (let index = 0; index < images.length; index++) {
  //       var image_height = parseFloat(
  //         $(images[index]).css("height").replace("px", "")
  //       );
  //       var image_width = parseFloat(
  //         $(images[index]).css("width").replace("px", "")
  //       );
  //       var new_width = (MainWidth / _Width) * image_width * 0.063;
  //       if (type == "showAnswer") {
  //         $(images[index]).css("width", new_width * 1 + size);
  //       } else {
  //         $(images[index]).css("width", new_width * 0.52 + size);
  //       }
  //       if (image_height < 11) {
  //         $(images[index]).css("top", "-2px");
  //       }
  //       $(images[index]).attr("old", image_height);
  //       $(images[index]).css("height", "auto");
  //       $(images[index]).css("position", "relative");
  //     }
  //   } else if (isTablet) {
  //     MainHeight = 36.5;
  //     MainWidth = 276.89;
  //     for (let index = 0; index < images.length; index++) {
  //       var image_height = parseFloat(
  //         $(images[index]).css("height").replace("px", "")
  //       );
  //       var image_width = parseFloat(
  //         $(images[index]).css("width").replace("px", "")
  //       );
  //       var new_width = (MainWidth / _Width) * image_width * 0.063;

  //       if (image_height < 11) {
  //         $(images[index]).css("top", "-2px");
  //       }
  //       $(images[index]).attr("old", image_height);
  //       $(images[index]).css("width", new_width * 0.63 + size);
  //       $(images[index]).css("height", "auto");
  //       $(images[index]).css("position", "relative");
  //       $(images[index]).attr("resize", "isTablet");
  //     }
  //   } else {
  //     MainHeight = 23;
  //     MainWidth = 181.95;
  //     for (let index = 0; index < images.length; index++) {
  //       var image_height = parseFloat(
  //         $(images[index]).css("height").replace("px", "")
  //       );
  //       var image_width = parseFloat(
  //         $(images[index]).css("width").replace("px", "")
  //       );
  //       var new_width = (MainWidth / _Width) * image_width * 0.063;
  //       if (type == "showAnswer") {
  //         $(images[index]).css("width", new_width * 0.97 + size);
  //       } else {
  //         $(images[index]).css("width", new_width * 0.83 + size);
  //       }
  //       $(images[index]).css("height", "auto");
  //       $(images[index]).css("position", "relative");
  //     }
  //   }

  //   let cont = $("#txt").find("div");
  //   for (let index = 0; index < cont.length; index++) {
  //     if ($(cont[index]).css("text-align") == "left") {
  //       $(cont[index]).attr("dir", "ltr");
  //     }
  //   }

  //   let cont_2 = $("#txt").find("p");
  //   for (let index = 0; index < cont_2.length; index++) {
  //     if ($(cont_2[index]).css("text-align") == "left") {
  //       $(cont_2[index]).attr("dir", "ltr");
  //     }
  //   }

  //   let content = $("#txt").html();
  //   setContentTxt(content);
  //   $("#txt").html("");
  //   // return content;
  };

  return (
    <div className={`w-full`}>
      <div id="txt" className="hidden"></div>
      <div
        className={`font-[roman] rtl:font-[Droid] text-[20px] leading-[40px] lg:leading-[50px] text-left rtl:text-right `}
        dangerouslySetInnerHTML={{
          __html: contentTxt,
        }}
      ></div>
    </div>
  );
};
