import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CanvasScreen from "../canvasScreen/CanvasScreen";
import {
  AccessibleScreen,
  Controllers,
  Pagination
} from "../components";
import Loading from "../components/examCompo/Loading";
import NoQuestions from "../components/examCompo/NoQuestions";
import TheRenderQuesBody from "../components/questions/AglopalQuestionMethod/RenderQuesBody";
import ScreenBar from './../canvasScreen/components/ScreenBar';
import {
  setBriefReport,
  setCurrentQues,
  setExamConfig,
  setExamCounter,
  setLang,
  setQuestionsList,
} from "../store/actions";

export const Exam = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [exams, setExams] = useState([]);
  const [filterQuestion, setFilterQuestion] = useState([]);
  const {
    lang,
    currentQues,

    essayModal,
  } = useSelector((state) => state.userAppReducer);
  const [questions, setQuestions] = useState(exams);
  const [userIfAnswer, setUserIfAnswer] = useState(false);
  const [accessable, setAccessable] = useState(true);
  const [loading, setLoading] = useState(true);
  const mytoken = localStorage.getItem("token");
  const headers = {
    "access-token": "qwertasdfg123@+1",
    Authorization: `Bearer ${mytoken}`,
  };
  useEffect(() => {
    getExam();
  }, [userIfAnswer]);
  const getExam = async () => {
    await axios
      .get(
        `https://emmobank.com/api/get_exam_v2?exam_id=${params.id}&subject_id=${
          params.subjectID
        }&old_width=${
          parseFloat(window.innerWidth) < 465 ? window.innerWidth : 465
        }&new_width=${window.innerHeight}`,
        {
          headers: headers,
        }
      )
      .then(async (respons) => {
        const allowedQuesTypes = [
          "rightWrong",
          "mcq",
          "maq",
          "essay",
          "blank",
          "order",
          "table",
          "chooseInLine",
          "connect",
        ];
        function filterQuestions(exam) {
          const filteredQuestions = exam.questions.filter((question) => {
            const isAllowed = allowedQuesTypes.includes(question.QuesType);

            return isAllowed;
          });
          // console.log(filteredQuestions.length);
          dispatch(setExamCounter(filteredQuestions.length));

          return filteredQuestions;
        }

        setFilterQuestion(filterQuestions(respons.data.exam));
    //     console.log(filterQuestion);

        setExams(respons.data.exam);
        setQuestions(respons.data.exam);
        dispatch(setBriefReport(true));
        return respons.data.exam;
      })
      .then((e) => {

        setTimeout(() => {
          setLoading(false);
          dispatch(setCurrentQues(0));
          dispatch(setQuestionsList(e.questions));
          dispatch(setLang(e.meta_data.language));

          dispatch(setExamConfig(questions));
        }, 10);
      })
      .catch(() => {})
      .finally(() => {});
  };

 
  
  const gotoDivision = () => {
    navigate("/home");
  };

  if (exams?.questions?.length === 0) {
    return (
      <>
        <NoQuestions />
      </>
    );
  }

  if (questions?.questions && !loading) {
    return (
      <>

      <ScreenBar/>

      <CanvasScreen language={questions?.meta_data.language}>
        <div
          dir={questions?.meta_data.language === "ar" ? "rtl" : "ltr"}
          className={`w-full absolute z-10 h-screen p-0 bg-gray-100 flex flex-col font-[Almarai] md:m-auto `}
        >
          <div id="loadImagesToLocal" className="hidden"></div>
          {accessable === true ? (
            true ? (
              <>
                <div    
 className="flex flex-col   ">
                  <div className=" relative p-1  bg-white    rounded-b-xl  shadow-lg  ">
                    <TheRenderQuesBody
                      question={filterQuestion?.[currentQues]}
                    />
                  </div>
                </div>

                <div className="flex flex-col h-fit justify-center ">
                  <Controllers
                    lang={questions?.meta_data.language === "ar" ? "ar" : "en"}
                  />

                  <Pagination type={filterQuestion} />
                </div>

            
              </>
            ) : (
              ""
            )
          ) : (
            <AccessibleScreen
              active={true}
              lang={lang}
              action={() => gotoDivision()}
            />
          )}
        </div>
      </CanvasScreen>

      </>
    );
  } else {
    return (
      <>
        <Loading />
      </>
    );
  }
};
