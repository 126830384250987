import axios from "axios";
import React, { useEffect, useState } from "react";

export default function ReviewRow({
  quData,
  imgae,
  idOfQuestion,
  onFiltersChange,
}) {
  const [loading, setloading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedSemester, setSelectedSemester] = useState(""); // الفصل الدراسي
  const [statuses, setStatuses] = useState([]);
  const [semesters] = useState([1, 2, 3]); // الفصول الدراسية المتاحة

  const [selectedEducationalLevel, setSelectedEducationalLevel] = useState("");
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");

  const [educationalLevels, setEducationalLevels] = useState([]);
  const [grades, setGrades] = useState([]);
  const [subjects, setSubjects] = useState([]);

  function sendItsDone(id) {
    setloading(true);
    const token = localStorage.getItem("token");

    const headers = {
      "access-token": "qwertasdfg123@+1",
      Authorization: `Bearer ${token}`,
    };

    const dataToSend = {
      issue_id: idOfQuestion || quData?.id || quData?.issues[0]?.id,
      status: "DONE",
    };

    const serverURL = "https://emmobank.com/api/change_issue_status";

    axios
      .post(serverURL, dataToSend, { headers })
      .then((response) => {
        setloading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setloading(false);
      });
  }

  useEffect(() => {
    const fetchData = async () => {
      const mytoken = localStorage.getItem("token");
      const headers = {
        "access-token": "qwertasdfg123@+1",
        Authorization: `Bearer ${mytoken}`,
      };

      try {
        const response = await axios.get(
          "https://emmobank.com/api/filter_data",
          { headers }
        );
        setStatuses(response.data.teacher_question_statuses);

        setStatuses(response.data.teacher_question_statuses);
        setEducationalLevels(response.data.educational_levels);
        setGrades(response.data.grades);
        setSubjects(response.data.subjects);
      } catch (error) {
        console.error("Error fetching filter data:", error);
      }
    };

    fetchData();
  }, []);

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleSemesterChange = (event) => {
    setSelectedSemester(event.target.value);
  };

  const handleSearch = () => {
    onFiltersChange({
      status: selectedStatus,
      semester_id: selectedSemester,
      educationalLevel: selectedEducationalLevel,
      grade: selectedGrade,
      subject: selectedSubject,
    });
  };

  return (
    <div className="z-50 flex justify-center bg-white border border-1 p-1 border-indigo-600">
      <div dir={"ar"}>
        <button
          onClick={() => sendItsDone(quData?.question_id)}
          className="  p-2 m-1 bg-white border-4 border-green-500 rounded-xl"
        >
          {!loading ? "تم الموافقة عليه" : "جارى ارسال الطلب.. ..."}
        </button>
      </div>

      <div className="   justify-center flex flex-row flex-wrap  bg-white border border-1 p-1  ">
        <div className="border p-2 m-1 bg-white">
          <select
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
          >
            <option value="">اختر الحالة...</option>
            {statuses.map((status) => (
              <option key={status.id} value={status.id}>
                {status.name_ar}
              </option>
            ))}
          </select>
        </div>
        <div className="border p-2 m-1 bg-white">
          <select value={selectedSemester} onChange={handleSemesterChange}>
            <option value="">اختر الفصل الدراسي...</option>
            {semesters.map((semester) => (
              <option key={semester} value={semester}>
                الفصل {semester}
              </option>
            ))}
          </select>
        </div>
        <div className="border p-2 m-1 bg-white">
          <select
            value={selectedEducationalLevel}
            onChange={(e) => setSelectedEducationalLevel(e.target.value)}
          >
            <option value="">اختر المرحلة التعليمية...</option>
            {educationalLevels.map((level) => (
              <option key={level.id} value={level.id}>
                {level.name}
              </option>
            ))}
          </select>
        </div>

        <div className="border p-2 m-1 bg-white">
          <select
            value={selectedGrade}
            onChange={(e) => setSelectedGrade(e.target.value)}
          >
            <option value="">اختر الصف...</option>
            {grades.map((grade) => (
              <option key={grade.id} value={grade.id}>
                {grade.name}
              </option>
            ))}
          </select>
        </div>

        <div className="border p-2 m-1 bg-white">
          <select
            value={selectedSubject}
            onChange={(e) => setSelectedSubject(e.target.value)}
          >
            <option value="">اختر المادة...</option>
            {subjects.map((subject) => (
              <option key={subject.id} value={subject.id}>
                {subject.name}
              </option>
            ))}
          </select>
        </div>

        <div className="border p-2 m-1 bg-white">
          <button className=" flex flex-row gap-3" onClick={handleSearch}>
            بحث
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}
